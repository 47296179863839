import React from 'react';
import { FormSection } from 'redux-form';
import { Channel } from '../../../../../schema';
import CustomerOptionsProvider from '../../../Customer/useOptions';
import Customer from '../../../Workflow/Finance/application/Form/Customer';
import TradeInVehicle from '../../../Workflow/Finance/shared/TradeInVehicle';
import Vehicle from '../../../Workflow/common/components/Vehicle';
import SectionSummary from './SectionSummary';
import { useAppointmentFormContext } from './context';

const TabAppointmentDetails = () => {
    const { values, disabled } = useAppointmentFormContext();
    const { latestApplication } = values;

    const hasTradeIn = [Channel.USED, Channel.EVENT].includes(latestApplication.channel);

    return (
        <>
            <SectionSummary channel={latestApplication.channel} dealerId={latestApplication.dealerId} />
            <FormSection name="latestApplication">
                <Vehicle disabled={disabled} />
                <CustomerOptionsProvider>
                    <Customer application={latestApplication} canAddGuarantor={false} disabled={disabled} />
                </CustomerOptionsProvider>
                {hasTradeIn && <TradeInVehicle />}
            </FormSection>
        </>
    );
};

export default TabAppointmentDetails;
