import { get } from 'lodash/fp';
import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { BankPresetOption } from '../../../../../schema';
import { yesNoOptions, signingModes, bankPresetOptions } from '../../../../../shared/constants/options';
import DropdownField from '../../../../template/Dropdown';
import FieldContainer from '../../../../template/Field-container';
import FileUpload from '../../../../template/FileUpload';
import ApvPortlet from '../../../../template/Portlet';
import SwitchField from '../../../../template/Switch';
import BankIntegration from './BankIntegration';
import MyInfoIntegration from './MyInfoIntegration';
import { useFormContext } from './context';

const FinanceApplication = () => {
    const { values, change } = useFormContext();
    const isKYCMandatory = get('isKYCMandatory', values);
    const kycPreset = get('presetOption', values);

    useEffect(() => {
        if (!isKYCMandatory) {
            change('hasMyInfoIntegration', false);
        }
    }, [isKYCMandatory, change]);

    return (
        <ApvPortlet name="Finance Application" closable open>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Submission Approval">
                            <Field component={SwitchField} name="signing.onCreate" options={signingModes} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Resubmission Approval">
                            <Field component={SwitchField} name="signing.onUpdate" options={signingModes} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Bank Integration">
                            <Field component={SwitchField} name="hasIntegration" options={yesNoOptions} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Occupation (KYC)">
                            <Field component={SwitchField} name="isKYCMandatory" options={yesNoOptions} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Upload VSO (Vehicle Sales Order)">
                            <Field component={SwitchField} name="hasVSOUpload" options={yesNoOptions} />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Send Email to Customer">
                            <Field
                                component={SwitchField}
                                name="sendCustomerConfirmationEmail"
                                options={yesNoOptions}
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="KYC Form Option">
                            <DropdownField
                                disabled={!isKYCMandatory}
                                name="presetOption"
                                options={bankPresetOptions}
                                placeholder="Select KYC Form Option"
                                noSort
                            />
                        </FieldContainer>
                    </div>
                    {kycPreset === BankPresetOption.ENBDBANK && (
                        <>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="ENBD Quotation Stamp Right">
                                    <Field
                                        accept=".jpg,.png,.jpeg,.svg"
                                        component={FileUpload}
                                        label="File in jpg, png or svg"
                                        name="stamps.enbdQuotationRight"
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="ENBD Quotation Stamp Bottom">
                                    <Field
                                        accept=".jpg,.png,.jpeg,.svg"
                                        component={FileUpload}
                                        label="File in jpg, png or svg"
                                        name="stamps.enbdQuotationBottom"
                                    />
                                </FieldContainer>
                            </div>
                        </>
                    )}

                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="MyInfo Integration">
                            <Field
                                component={SwitchField}
                                disabled={!isKYCMandatory}
                                name="hasMyInfoIntegration"
                                options={yesNoOptions}
                            />
                        </FieldContainer>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Guarantor Included">
                            <Field component={SwitchField} name="isGuarantorIncluded" options={yesNoOptions} />
                        </FieldContainer>
                    </div>
                </div>
                <BankIntegration />
                <MyInfoIntegration />
            </div>
        </ApvPortlet>
    );
};

export default FinanceApplication;
