import { size } from 'lodash/fp';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Channel, FinanceProductSubType, FinanceProductType } from '../../../../../../schema';
import { getGlobalPermissions } from '../../../../../../selectors';
import CustomerOptionsProvider from '../../../../Customer/useOptions';
import Vehicle from '../../../common/components/Vehicle';
import Summary from '../../../common/shared/Summary';
import Customer from '../../application/Form/Customer';
import Finance from '../../shared/Finance';
import TradeInVehicle from '../../shared/TradeInVehicle';
import { useFormContext } from './context';

const Lead = () => {
    const { state } = useLocation();
    const { disabled, values } = useFormContext();
    const hasSubMonthly = size(values?.calculator?.monthlyInstalments) > 1;

    const { mayManageLeads } = useSelector(getGlobalPermissions);

    const hasTradeIn = [Channel.USED, Channel.EVENT].includes(values.channel);

    const isLeasing =
        values?.financeProduct?.type === FinanceProductType.LEASING &&
        values?.financeProduct?.subType === FinanceProductSubType.LEASE;

    return (
        <>
            <Summary
                applicationPhase={values.phase}
                channel={values.channel}
                dealerId={values.dealerId}
                disabled={!mayManageLeads}
                id={values.id}
            />
            {state?.showCustomerSection && (
                <CustomerOptionsProvider>
                    <Customer disabled />
                </CustomerOptionsProvider>
            )}
            {hasTradeIn && <TradeInVehicle />}
            <Vehicle />
            <Finance disabled={disabled} hasSubMonthly={hasSubMonthly} isLeasing={isLeasing} values={values} />
        </>
    );
};

export default Lead;
