import { get } from 'lodash/fp';
import { ChatbotType, MaskDirection, PaymentProviderType, Provider } from '../../../../../schema';
import * as validators from '../../../../../utils/validators';

const mandatoryKeys = [
    'type',
    'project_id',
    'private_key_id',
    'private_key',
    'client_email',
    'client_id',
    'auth_uri',
    'token_uri',
    'auth_provider_x509_cert_url',
    'client_x509_cert_url',
];

/* this can be changed by Google and we need to update if needed */
/* most of the time there are 6 characters after GTM- */
/* old IDs may have four characters after GTM- while newer IDs may have 7. */
const googleTagManagerIdRegex = /^GTM-[A-Z0-9]{4,7}$/;

const validate = validators.compose(
    validators.requiredString('code'),
    validators.requiredString('companyId'),
    validators.requiredValue('languages'),
    validators.requiredString('currency'),
    validators.requiredNumber('rounding.amount.count'),
    validators.requiredNumber('rounding.percentage.count'),
    validators.requiredBoolean('matchExistingCustomer'),
    validators.requiredBoolean('sendSalesPersonConfirmationEmail'),
    validators.requiredBoolean('useExternalCalculator'),
    validators.requiredString('email.provider'),
    validators.only(
        values => !get('matchExistingCustomer', values) && get('mask.direction', values) !== MaskDirection.NONE,
        validators.requiredNumber('mask.count')
    ),
    validators.only(
        values => get('email.provider', values) === Provider.SMTP,
        validators.compose(
            validators.requiredString('email.host'),
            validators.requiredNumber('email.port'),
            validators.requiredBoolean('email.isSslEnabled'),
            validators.requiredBoolean('email.isAuthenticationRequired'),
            validators.only(
                values => get('email.isAuthenticationRequired', values),
                validators.requiredString('email.user')
            )
        )
    ),
    validators.requiredString('email.fromEmail'),
    validators.validEmail('email.fromEmail'),
    validators.only(
        values => get('googleTagManager.id', values),
        validators.validValue(
            'googleTagManager.id',
            googleTagManagerIdRegex,
            'This Google Tag Manager ID is not valid.'
        )
    ),
    // only sg display COE
    validators.only(
        values =>
            get('code', values) === 'SG' &&
            (get('channelSetting.new.isCoeEnabled', values) || get('channelSetting.express.isCoeEnabled', values)),
        validators.compose(validators.requiredString('coe.disclaimer'), validators.requiredNumber('coe.amount'))
    ),

    // new channel
    validators.requiredBoolean('channelSetting.new.isActive'),
    validators.requiredBoolean('channelSetting.new.isCoeEnabled'),
    validators.requiredBoolean('channelSetting.new.allowReverseCalculator'),
    validators.requiredBoolean('channelSetting.new.allowOptions'),
    validators.requiredBoolean('channelSetting.new.allowSharing'),
    validators.requiredBoolean('channelSetting.new.isPromoCodeEnabled'),
    validators.requiredBoolean('channelSetting.new.allowPrivateAccess'),
    validators.requiredBoolean('channelSetting.new.allowPublicAccess'),
    validators.requiredBoolean('channelSetting.new.isDepositPaymentMandatory'),
    validators.requiredBoolean('channelSetting.new.allowFinanceApplication'),
    validators.requiredBoolean('channelSetting.new.isFinanceApplicationOptional'),
    validators.requiredBoolean('channelSetting.new.allowProceedWithCustomerDevice'),
    validators.requiredBoolean('channelSetting.new.filterByMonthlyInstalment'),
    validators.only(
        values => get('channelSetting.new.isChatbotEnabled', values),
        validators.compose(
            validators.requiredString('channelSetting.new.chatbot.type'),
            validators.only(
                values => get('channelSetting.new.chatbot.type', values) === ChatbotType.DIALOGFLOW,
                validators.compose(
                    validators.requiredString('channelSetting.new.chatbot.dialogflowCredentials'),
                    validators.validJson(
                        'channelSetting.new.chatbot.dialogflowCredentials',
                        'Invalid Credentials',
                        mandatoryKeys
                    )
                )
            ),
            validators.only(
                values => get('channelSetting.new.chatbot.type', values) === ChatbotType.USERLIKE,
                validators.compose(validators.requiredString('channelSetting.new.chatbot.userlikeCredentials'))
            )
        )
    ),
    validators.only(
        values => get('channelSetting.new.isDepositPaymentMandatory', values),
        validators.compose(
            validators.requiredNumber('channelSetting.new.bookingPayment.amount'),
            validators.requiredString('channelSetting.new.bookingPayment.provider.type'),
            validators.only(
                values => get('channelSetting.new.bookingPayment.provider.type', values) === PaymentProviderType.ADYEN,
                validators.compose(
                    validators.requiredString('channelSetting.new.bookingPayment.provider.settings.merchantAccount'),
                    validators.requiredString('channelSetting.new.bookingPayment.provider.settings.apiKey'),
                    validators.requiredString('channelSetting.new.bookingPayment.provider.settings.clientKey'),
                    validators.requiredString('channelSetting.new.bookingPayment.provider.settings.originKey')
                )
            ),
            validators.only(
                values =>
                    get('channelSetting.new.bookingPayment.provider.type', values) === PaymentProviderType.PAYGATE,
                validators.compose(
                    validators.requiredString('channelSetting.new.bookingPayment.provider.settings.apiKey'),
                    validators.requiredString('channelSetting.new.bookingPayment.provider.settings.encryptionKey')
                )
            )
        )
    ),

    // used channel
    validators.requiredBoolean('channelSetting.used.isActive'),
    validators.requiredBoolean('channelSetting.used.allowTestDrive'),
    validators.requiredBoolean('channelSetting.used.allowTradeIn'),
    validators.requiredBoolean('channelSetting.used.allowReverseCalculator'),
    validators.requiredBoolean('channelSetting.used.allowSharing'),
    validators.requiredBoolean('channelSetting.used.isPromoCodeEnabled'),
    validators.requiredBoolean('channelSetting.used.allowPrivateAccess'),
    validators.requiredBoolean('channelSetting.used.allowPublicAccess'),
    validators.requiredBoolean('channelSetting.used.isDepositPaymentMandatory'),
    validators.requiredBoolean('channelSetting.used.allowFinanceApplication'),
    validators.requiredBoolean('channelSetting.used.isFinanceApplicationOptional'),
    validators.requiredBoolean('channelSetting.used.allowProceedWithCustomerDevice'),
    validators.requiredBoolean('channelSetting.used.filterByMonthlyInstalment'),
    validators.only(
        values => get('channelSetting.used.isChatbotEnabled', values),
        validators.compose(
            validators.requiredString('channelSetting.used.chatbot.type'),
            validators.only(
                values => get('channelSetting.used.chatbot.type', values) === ChatbotType.DIALOGFLOW,
                validators.compose(
                    validators.requiredString('channelSetting.used.chatbot.dialogflowCredentials'),
                    validators.validJson(
                        'channelSetting.used.chatbot.dialogflowCredentials',
                        'Invalid Credentials',
                        mandatoryKeys
                    )
                )
            ),
            validators.only(
                values => get('channelSetting.used.chatbot.type', values) === ChatbotType.USERLIKE,
                validators.compose(validators.requiredString('channelSetting.used.chatbot.userlikeCredentials'))
            )
        )
    ),
    validators.only(
        values => get('channelSetting.used.isDepositPaymentMandatory', values),
        validators.compose(
            validators.requiredNumber('channelSetting.used.bookingPayment.amount'),
            validators.requiredString('channelSetting.used.bookingPayment.provider.type'),
            validators.only(
                values => get('channelSetting.used.bookingPayment.provider.type', values) === PaymentProviderType.ADYEN,
                validators.compose(
                    validators.requiredString('channelSetting.used.bookingPayment.provider.settings.merchantAccount'),
                    validators.requiredString('channelSetting.used.bookingPayment.provider.settings.apiKey'),
                    validators.requiredString('channelSetting.used.bookingPayment.provider.settings.clientKey'),
                    validators.requiredString('channelSetting.used.bookingPayment.provider.settings.originKey')
                )
            ),
            validators.only(
                values =>
                    get('channelSetting.used.bookingPayment.provider.type', values) === PaymentProviderType.PAYGATE,
                validators.compose(
                    validators.requiredString('channelSetting.used.bookingPayment.provider.settings.apiKey'),
                    validators.requiredString('channelSetting.used.bookingPayment.provider.settings.encryptionKey')
                )
            )
        )
    ),

    // express channel
    validators.requiredBoolean('channelSetting.express.isActive'),
    validators.requiredBoolean('channelSetting.express.isCoeEnabled'),
    validators.requiredBoolean('channelSetting.express.allowReverseCalculator'),
    validators.requiredBoolean('channelSetting.express.isPromoCodeEnabled'),
    validators.requiredBoolean('channelSetting.express.isDepositPaymentMandatory'),
    validators.requiredBoolean('channelSetting.express.allowFinanceApplication'),
    validators.requiredBoolean('channelSetting.express.isFinanceApplicationOptional'),
    validators.requiredBoolean('channelSetting.express.allowProceedWithCustomerDevice'),
    validators.only(
        values => get('channelSetting.express.isDepositPaymentMandatory', values),
        validators.compose(
            validators.requiredNumber('channelSetting.express.bookingPayment.amount'),
            validators.requiredString('channelSetting.express.bookingPayment.provider.type'),
            validators.only(
                values =>
                    get('channelSetting.express.bookingPayment.provider.type', values) === PaymentProviderType.ADYEN,
                validators.compose(
                    validators.requiredString(
                        'channelSetting.express.bookingPayment.provider.settings.merchantAccount'
                    ),
                    validators.requiredString('channelSetting.express.bookingPayment.provider.settings.apiKey'),
                    validators.requiredString('channelSetting.express.bookingPayment.provider.settings.clientKey'),
                    validators.requiredString('channelSetting.express.bookingPayment.provider.settings.originKey')
                )
            ),
            validators.only(
                values =>
                    get('channelSetting.express.bookingPayment.provider.type', values) === PaymentProviderType.PAYGATE,
                validators.compose(
                    validators.requiredString('channelSetting.express.bookingPayment.provider.settings.apiKey'),
                    validators.requiredString('channelSetting.express.bookingPayment.provider.settings.encryptionKey')
                )
            )
        )
    ),

    // event channel
    validators.requiredBoolean('channelSetting.event.isActive'),
    validators.requiredBoolean('channelSetting.event.isPromoCodeEnabled'),
    validators.only(
        values => get('channelSetting.event.isChatbotEnabled', values),
        validators.compose(
            validators.requiredString('channelSetting.event.chatbot.type'),
            validators.only(
                values => get('channelSetting.event.chatbot.type', values) === ChatbotType.DIALOGFLOW,
                validators.compose(
                    validators.requiredString('channelSetting.event.chatbot.dialogflowCredentials'),
                    validators.validJson(
                        'channelSetting.event.chatbot.dialogflowCredentials',
                        'Invalid Credentials',
                        mandatoryKeys
                    )
                )
            ),
            validators.only(
                values => get('channelSetting.event.chatbot.type', values) === ChatbotType.USERLIKE,
                validators.compose(validators.requiredString('channelSetting.event.chatbot.userlikeCredentials'))
            ),
            validators.only(
                values => get('channelSetting.event.chatbot.type', values) === ChatbotType.HERO,
                validators.compose(validators.requiredString('channelSetting.event.chatbot.heroCredentials'))
            )
        )
    ),
    validators.only(
        get('channelSetting.event.hasMyInfoIntegration'),
        validators.compose(
            validators.requiredString('channelSetting.event.myInfo.clientId'),
            validators.requiredString('channelSetting.event.myInfo.clientSecret'),
            validators.requiredString('channelSetting.event.myInfo.publicCert'),
            validators.requiredString('channelSetting.event.myInfo.privateKey'),
            validators.requiredString('channelSetting.event.myInfo.purpose')
        )
    ),
    validators.requiredBoolean('channelSetting.event.isDepositPaymentMandatory'),
    validators.only(
        values => get('channelSetting.event.isDepositPaymentMandatory', values),
        validators.compose(
            validators.requiredNumber('channelSetting.event.bookingPayment.amount'),
            validators.requiredString('channelSetting.event.bookingPayment.provider.type'),
            validators.only(
                values =>
                    get('channelSetting.event.bookingPayment.provider.type', values) === PaymentProviderType.ADYEN,
                validators.compose(
                    validators.requiredString('channelSetting.event.bookingPayment.provider.settings.merchantAccount'),
                    validators.requiredString('channelSetting.event.bookingPayment.provider.settings.apiKey'),
                    validators.requiredString('channelSetting.event.bookingPayment.provider.settings.clientKey'),
                    validators.requiredString('channelSetting.event.bookingPayment.provider.settings.originKey')
                )
            ),
            validators.only(
                values =>
                    get('channelSetting.event.bookingPayment.provider.type', values) === PaymentProviderType.PAYGATE,
                validators.compose(
                    validators.requiredString('channelSetting.event.bookingPayment.provider.settings.apiKey'),
                    validators.requiredString('channelSetting.event.bookingPayment.provider.settings.encryptionKey')
                )
            )
        )
    )
);

export default validate;
