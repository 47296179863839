import { useQuery } from '@apollo/client';
import { get, isNil } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { ErrorMessageDiv } from '../../../../../containers/Layout';
import { AssetCondition, CalculatorVisibility, EventExternalSite, RuntimeType } from '../../../../../schema';
import { getCompanyIdentifier, getCurrentCountry, getRuntimeSettings } from '../../../../../selectors';
import {
    onOffOptions,
    eventAccessOptions,
    calculatorVisibilityOptions,
    applyCoeVehicleConditionOptions,
} from '../../../../../shared/constants/options';
import CurrencyField from '../../../../template/Currency';
import FieldContainer from '../../../../template/Field-container';
import NumberField from '../../../../template/Number';
import SwitchField from '../../../../template/Switch';
import TextareaField from '../../../../template/Textarea';
import { getItem } from '../Edition.graphql';
import PriceDisclaimer from './PriceDisclaimer';
import { useFormContext } from './context';

const warningMessage =
    'Note: Car of Interest selection is based on new vehicles which is currently not set up in the system.';

const useExternalSiteOptions = () => {
    const { usePorscheFinder, useIccCheckout } = useSelector(getCurrentCountry);
    const { companyCode, countryCode } = useSelector(getCompanyIdentifier);

    const isPorsche = companyCode === 'POR';

    return useMemo(
        () =>
            [
                { label: 'None', value: EventExternalSite.NONE },
                usePorscheFinder && { label: 'Porsche Finder', value: EventExternalSite.PORSCHEFINDER },
                isPorsche && countryCode === 'MY' && { label: 'Configurator', value: EventExternalSite.CONFIGURATOR },
                { label: 'Marketing Reconsent', value: EventExternalSite.MARKETINGRECONSENT },
                useIccCheckout && { label: 'ICC', value: EventExternalSite.ICC },
            ].filter(Boolean),
        [countryCode, isPorsche, usePorscheFinder, useIccCheckout]
    );
};

const Settings = () => {
    const { disabled, values, change, countryCode, currency, rounding } = useFormContext();
    const externalSiteOptions = useExternalSiteOptions();
    const isCoeEnabled = countryCode === 'SG';

    const isPpsrAndEstablishmentEnabled = countryCode === 'NZ';
    const allowFinder = get('setting.externalSite', values) === EventExternalSite.PORSCHEFINDER;
    const allowIcc = get('setting.externalSite', values) === EventExternalSite.ICC;
    const configurator = get('setting.externalSite', values) === EventExternalSite.CONFIGURATOR;
    const isMarketingReconsent = get('setting.externalSite', values) === EventExternalSite.MARKETINGRECONSENT;
    const allowReservationInstruction = get('setting.isReservationInstructionEnabled', values);

    const allowTestDrive = get('setting.allowTestDrive', values);

    const tradeIn = get('setting.allowTradeIn', values);
    const tradeInAmountInput = get('setting.allowTradeInAmountInput', values);

    const isEventSettingCoeEnabled = get('setting.isCoeEnabled', values);
    const isEventDepositPaymentMandatory = get('setting.isDepositPaymentMandatory', values);

    const calculatorVisibility = get('setting.calculatorVisibility', values);

    const { type } = useSelector(getRuntimeSettings);

    useEffect(() => {
        if (!allowFinder && !allowIcc) {
            change('setting.enablePriceDisclaimer', false);
            change('setting.priceDisclaimers', []);
            change('setting.isCoeEnabled', false);
            change('setting.isCoeEditable', false);
            change('setting.isReservationInstructionEnabled', false);
            change('setting.reservationInstruction', null);
            change('setting.applyForFinancing', false);
        }
        if (!allowFinder && !configurator && !allowIcc) {
            change('setting.isPromoCodeEnabled', false);
            change('edm.isSummaryVehicleEnabled', false);
        }
        // promo will be always enabled in ckd for now
        if (configurator) {
            change('setting.isPromoCodeEnabled', true);
        }
    }, [allowFinder, allowIcc, change, configurator]);

    useEffect(() => {
        if (isMarketingReconsent) {
            change('edm', null);
        }
    }, [change, isMarketingReconsent]);

    useEffect(() => {
        if (!isEventSettingCoeEnabled) {
            change('setting.isCoeEditable', false);
        }
    }, [change, isEventSettingCoeEnabled]);

    useEffect(() => {
        if (isNil(tradeInAmountInput)) {
            change('setting.allowTradeInAmountInput', false);
        }
    }, [change, tradeInAmountInput]);

    useEffect(() => {
        if (calculatorVisibility === CalculatorVisibility.OFF) {
            change('setting.applyForFinancing', false);
        }
    }, [calculatorVisibility, change]);

    //  get the country ID
    const { id: countryId, channelSetting } = useSelector(getCurrentCountry);

    // from there get the channel variants
    const variables = { countryId, activeOnly: true };
    const { data, loading } = useQuery(getItem, { variables, fetchPolicy: 'network-only' });

    const hasNewVariants = useMemo(() => {
        const variants = data?.getVariantsByCountryId?.items || [];

        return variants.some(
            variant => variant.assetCondition === AssetCondition.NEW || variant.assetCondition === AssetCondition.DEMO
        );
    }, [data]);

    const handleTradeInChange = useCallback(
        value => {
            if (!value) {
                change('setting.allowTradeInAmountInput', false);
            }
        },
        [change]
    );

    if (loading) {
        // not yet available
        return null;
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Access">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="setting.access"
                            options={eventAccessOptions}
                        />
                    </FieldContainer>
                </div>
                {channelSetting.event.isDepositPaymentMandatory && (
                    <>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Payment Deposit">
                                <Field
                                    component={SwitchField}
                                    disabled={disabled}
                                    name="setting.isDepositPaymentMandatory"
                                    options={onOffOptions}
                                />
                            </FieldContainer>
                        </div>
                        {isEventDepositPaymentMandatory && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Payment Amount">
                                    <Field
                                        component={CurrencyField}
                                        disabled={disabled}
                                        name="setting.bookingPayment.amount"
                                        precision={rounding?.amount?.count}
                                        prefix={currency}
                                    />
                                </FieldContainer>
                            </div>
                        )}
                    </>
                )}
                {channelSetting.event.isChatbotEnabled && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Chatbot">
                            <Field
                                component={SwitchField}
                                disabled={disabled}
                                name="setting.isChatbotEnabled"
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Trade-In">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="setting.allowTradeIn"
                            onChange={handleTradeInChange}
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                {tradeIn && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Trade-In Amount Input">
                            <Field
                                component={SwitchField}
                                disabled={disabled}
                                name="setting.allowTradeInAmountInput"
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="Test Drive">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="setting.allowTestDrive"
                            options={onOffOptions}
                        />
                    </FieldContainer>
                </div>
                {allowTestDrive && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Appointment">
                            <Field
                                component={SwitchField}
                                disabled={disabled}
                                name="setting.isAppointmentEnabled"
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <FieldContainer label="External Site">
                        <Field
                            component={SwitchField}
                            disabled={disabled}
                            name="setting.externalSite"
                            options={externalSiteOptions}
                        />
                        {get('setting.externalSite', values) === EventExternalSite.NONE && !hasNewVariants && (
                            <ErrorMessageDiv>{warningMessage}</ErrorMessageDiv>
                        )}
                    </FieldContainer>
                </div>

                {channelSetting.event.isPromoCodeEnabled && (
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <FieldContainer label="Promo Code">
                            <Field
                                component={SwitchField}
                                disabled={disabled}
                                name="setting.isPromoCodeEnabled"
                                options={onOffOptions}
                            />
                        </FieldContainer>
                    </div>
                )}

                {(allowFinder || allowIcc) && (
                    <>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Price Disclaimers">
                                <Field
                                    component={SwitchField}
                                    disabled={disabled}
                                    name="setting.enablePriceDisclaimer"
                                    options={onOffOptions}
                                />
                            </FieldContainer>
                        </div>
                        {isCoeEnabled && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="COE">
                                    <Field
                                        component={SwitchField}
                                        disabled={disabled}
                                        name="setting.isCoeEnabled"
                                        options={onOffOptions}
                                    />
                                </FieldContainer>
                            </div>
                        )}
                        {isEventSettingCoeEnabled && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="COE - Editable">
                                    <Field
                                        component={SwitchField}
                                        disabled={disabled}
                                        name="setting.isCoeEditable"
                                        options={onOffOptions}
                                    />
                                </FieldContainer>
                            </div>
                        )}
                        {allowFinder && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Apply for Vehicle Condition is">
                                    <Field
                                        component={SwitchField}
                                        disabled={disabled}
                                        name="setting.coeVehicleCondition"
                                        options={applyCoeVehicleConditionOptions}
                                    />
                                </FieldContainer>
                            </div>
                        )}
                        {isPpsrAndEstablishmentEnabled && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="PPSR And Establishment Fees">
                                    <Field
                                        component={SwitchField}
                                        disabled={disabled}
                                        name="setting.isPpsrAndEstablishmentEnabled"
                                        options={onOffOptions}
                                    />
                                </FieldContainer>
                            </div>
                        )}
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Reservation Instruction">
                                <Field
                                    component={SwitchField}
                                    disabled={disabled}
                                    name="setting.isReservationInstructionEnabled"
                                    options={onOffOptions}
                                />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Calculator">
                                <Field
                                    component={SwitchField}
                                    disabled={disabled}
                                    name="setting.calculatorVisibility"
                                    options={calculatorVisibilityOptions(type === RuntimeType.PME)}
                                />
                            </FieldContainer>
                        </div>
                        {calculatorVisibility !== CalculatorVisibility.OFF && (
                            <div className="col-md-4 col-sm-12 col-xs-12">
                                <FieldContainer label="Apply For Financing">
                                    <Field
                                        component={SwitchField}
                                        disabled={disabled}
                                        name="setting.applyForFinancing"
                                        options={onOffOptions}
                                    />
                                </FieldContainer>
                            </div>
                        )}
                    </>
                )}
            </div>
            <PriceDisclaimer />
            {allowReservationInstruction && (
                <>
                    <hr />
                    <div className="row">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Reservation Instruction">
                                <Field component={TextareaField} name="setting.reservationInstruction" />
                            </FieldContainer>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <FieldContainer label="Reservation Period (in working days, exclude Sat & Sun)">
                                <Field component={NumberField} name="setting.reservationPeriod" />
                            </FieldContainer>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Settings;
