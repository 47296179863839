import { useMemo } from 'react';
import { BankPresetOption } from '../../../../../schema';

const useBankPresetStep = <T>(options: { [key in BankPresetOption]: T }, bankPreset?: BankPresetOption) => {
    return useMemo(() => {
        switch (bankPreset) {
            case BankPresetOption.AFFINBANK:
                return options[BankPresetOption.AFFINBANK];

            case BankPresetOption.PMESA:
                return options[BankPresetOption.PMESA];

            case BankPresetOption.ENBDBANK:
                return options[BankPresetOption.ENBDBANK];

            case BankPresetOption.NONE:
            default:
                return options[BankPresetOption.NONE];
        }
    }, [bankPreset, options]);
};

export default useBankPresetStep;
