import { size } from 'lodash/fp';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Channel, FinanceProductSubType, FinanceProductType } from '../../../../../../schema';
import { getGlobalPermissions } from '../../../../../../selectors';
import useFormValues from '../../../../../utilities/useFormValues';
import CustomerOptionsProvider from '../../../../Customer/useOptions';
import Vehicle from '../../../common/components/Vehicle';
import Summary from '../../../common/shared/Summary';
import Finance from '../../shared/Finance';
import PaymentDetails from '../../shared/PaymentDetails';
import RecalculateProvider from '../../shared/RecalculateProvider';
import TradeInVehicle from '../../shared/TradeInVehicle';
import AddGuarantorProvider from '../AddGuarantorProvider';
import Customer from './Customer';
import Guarantor from './Guarantor';
import { ApplicationFormValues, useFormContext } from './context';

const Application = () => {
    const { state } = useLocation<{ showCustomerSection: boolean }>();

    const { disabled, values, setCalculatorStatus, allowPrimaryInfoChanges } = useFormContext();

    const hasSubMonthly = size(values.calculator?.monthlyInstalments) > 1;

    const { mayManageApplications } = useSelector(getGlobalPermissions);

    // get form values
    const formValues = useFormValues<ApplicationFormValues>();

    const hasTradeIn = [Channel.USED, Channel.EVENT].includes(values.channel);

    const isLeasing =
        values?.financeProduct?.type === FinanceProductType.LEASING &&
        values?.financeProduct?.subType === FinanceProductSubType.LEASE;

    const hasPayment = !values.isLead && values.reservationDeposit;

    const backUrl = '/workflow/applications';

    return (
        <>
            <Summary
                applicationPhase={values.phase}
                channel={values.channel}
                dealerId={values.dealerId}
                disabled={!mayManageApplications}
                id={values.id}
            />
            {state?.showCustomerSection && (
                <CustomerOptionsProvider>
                    <AddGuarantorProvider application={values} backUrl={backUrl}>
                        {guarantorProps => (
                            <Customer
                                allowPrimaryInfoChanges={allowPrimaryInfoChanges}
                                disabled={disabled}
                                {...guarantorProps}
                            />
                        )}
                    </AddGuarantorProvider>
                    {formValues?.guarantor?.id && <Guarantor disabled={disabled} />}
                </CustomerOptionsProvider>
            )}
            {hasTradeIn && <TradeInVehicle />}
            <Vehicle disabled={disabled} />
            {hasPayment && <PaymentDetails application={values} />}
            <RecalculateProvider application={values} form="application" setCalculatorStatus={setCalculatorStatus}>
                {openRecalculate => (
                    <Finance
                        disabled={disabled}
                        hasSubMonthly={hasSubMonthly}
                        isLeasing={isLeasing}
                        recalculate={openRecalculate}
                        values={values}
                        isApplication
                    />
                )}
            </RecalculateProvider>
        </>
    );
};

export default Application;
