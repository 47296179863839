// @ts-ignore
import { Portlet } from '@appvantageasia/afc-ui';
import React from 'react';
import ContactDetails from './ContactDetails';
import CustomerDetails from './CustomerDetails';
import IdentityDetails from './IdentityDetails';
import OccupationDetails from './OccupationDetails';
import QuotationDetails from './QuotationDetails';
import ReferenceDetails from './ReferenceDetails';

const ApplicantDetails = () => {
    return (
        <Portlet name="Applicant Details" closable open>
            <div className="container-fluid">
                <CustomerDetails />
                <IdentityDetails />
                <ReferenceDetails />
                <ContactDetails />
                <OccupationDetails />
                <QuotationDetails />
            </div>
        </Portlet>
    );
};

export default ApplicantDetails;
