import { useQuery } from '@apollo/client';
import { get, isNil } from 'lodash/fp';
import React, { useCallback, useState, useMemo, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FootBar, FootBarButton, FootContainer } from '../../../../../containers/Layout';
import { useContentTranslation } from '../../../../../i18n';
import { ApplicationPhase, EventExternalSite, PaymentProviderType } from '../../../../../schema';
import { getZoneId, getCurrentCountry } from '../../../../../selectors';
import useFormatDateTime from '../../../../shared/useFormatDateTime';
import { List, cells, ListSearch, DealerSelectionDropDown } from '../../../../ui/lists';
import { getData } from '../../../../ui/lists/DealerSelectionDropDown.graphql';
import useServerPaging from '../../../../utilities/useServerPaging';
import {
    ApplicationListDataFragment,
    getTables,
    getTablesByCountryId,
} from '../../Finance/lists/ApplicationList.graphql';
import DownloadModal from '../../Finance/lists/Download';
import { getSourceLabel } from '../../Finance/lists/LeadList';

const useSearchFields = () => {
    const { formatPath } = useContentTranslation();

    return useMemo(
        () => [
            'identifier',
            'customer.name.value',
            'assignee.username',
            formatPath('variant.name'),
            formatPath('financeProduct.name'),
            'status',
            'channel',
            'transactionID',
        ],
        [formatPath]
    );
};

export const getReservationTransactionId = (application: ApplicationListDataFragment) => {
    switch (application?.payment?.channel?.type) {
        case PaymentProviderType.PORSCHE:
        case PaymentProviderType.PAYGATE:
            return get('payment.channel.details.transactionId', application) ?? '';

        case PaymentProviderType.ADYEN:
            return get('payment.channel.details.pspReference', application) ?? '';

        default:
            return '';
    }
};

const useColumns = (dealers: string[]) => {
    const formatDateTime = useFormatDateTime();
    const { formatPath } = useContentTranslation();

    return useMemo(() => {
        const columns = [
            {
                name: 'Reservation Date',
                id: 'version.createdAt',
                renderCell: cells.renderDateTime('version.createdAt', formatDateTime),
                hasSort: true,
            },
            {
                name: 'App ID',
                id: 'identifier',
                renderCell: get('identifier'),
                underline: true,
                hasSort: true,
            },
            dealers?.length > 1 && {
                name: 'Dealer',
                id: 'dealer.name',
                renderCell: get(formatPath('dealer.name')),
                hasSort: true,
            },
            {
                name: 'Assigned to',
                id: 'assignee.name',
                renderCell: get('assignee.name'),
                hasSort: true,
            },
            {
                name: 'Customer',
                id: 'customer.name.value',
                renderCell: get('customer.name.value'),
                hasSort: true,
            },
            {
                name: 'Variant',
                id: formatPath('variant.name'),
                renderCell: get(formatPath('variant.name')),
                hasSort: true,
            },
            {
                name: 'Status',
                id: 'status',
                // @ts-ignore
                renderCell: cells.renderStatus(get('statusText')),
                hasSort: true,
            },
            {
                name: 'Transaction ID',
                id: 'transactionID',
                renderCell: get('transactionID'),
                hasSort: true,
            },
            {
                name: 'Last Activity',
                id: 'version.updatedAt',
                renderCell: cells.renderDateTime('version.updatedAt', formatDateTime),
                hasSort: true,
            },
            { name: 'Channel', id: 'channel', renderCell: get('source'), hasSort: true },
        ];

        return columns.filter(Boolean);
    }, [dealers, formatDateTime, formatPath]);
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'UPDATE':
            return { ...state, ...{ type: action.payload[0], order: action.payload[1] } };
        default:
            return state;
    }
};

const pageSetting = {
    phase: ApplicationPhase.RESERVATION,
    titleKey: 'applicationsListPage.reservationTitle',
    previousKey: 'applicationDetailsPage.button.reservationPrevious',
};

const ReservationList = () => {
    const history = useHistory();
    const [dealerIds, setDealerIds] = useState<null | string[]>(null);

    const { t } = useTranslation();

    const { id: countryId } = useSelector(getCurrentCountry);
    const { data: dataDealers } = useQuery(getData, {
        fetchPolicy: 'cache-first',
        variables: { countryId },
    });
    const dealers: string[] = dataDealers?.dealers ?? [];

    const zoneId = useSelector(getZoneId);

    // searching
    const [search, setSearch] = useState('');
    const searchFields = useSearchFields();

    // sorting
    const [sortedOn, dispatch] = useReducer(reducer, { type: 'identifier', order: 'DESC' });
    const [paging] = useServerPaging({ search });

    const { phase, titleKey } = pageSetting;

    const onSort = (sortKeys: any[]) => {
        dispatch({ type: 'UPDATE', payload: sortKeys });
    };

    // based on zone or channel
    const query = zoneId ? getTables : getTablesByCountryId;
    const variables = {
        id: zoneId || countryId,
        filter: {
            dealerIds,
            phase,
        },
        sorting: [
            {
                ...sortedOn,
                order: sortedOn.order.toUpperCase(),
            },
        ],
        paging: {
            limit: paging.pageSize,
            offset: (paging.page - 1) * paging.pageSize,
        },
        search: search
            ? searchFields.map(field => {
                  return { identifier: field, value: search };
              })
            : null,
    };

    const { data, loading, error } = useQuery(query, {
        fetchPolicy: 'cache-and-network',
        variables,
        skip: isNil(dealerIds),
    });

    const items = (data?.results?.items || []) as ApplicationListDataFragment[];
    const isLoading = loading && items.length <= 0;

    useEffect(() => {
        paging.setItemCount(data?.results?.count || 0);
    }, [paging, data]);

    const columns = useColumns(dealers);

    // Adding Source Field to Application Data
    const getChannel = get('channel');
    const getAccess = get('access');

    const itemsData = useMemo(
        () =>
            items.map(row => {
                const channel = getChannel(row);
                const access = getAccess(row);

                const source = getSourceLabel(channel, access);
                const transactionID = getReservationTransactionId(row);

                return {
                    ...row,
                    // hide variant for marketing reconsent
                    variant:
                        row.event?.setting.externalSite === EventExternalSite.MARKETINGRECONSENT ? null : row.variant,
                    source,
                    transactionID,
                };
            }),
        [getAccess, getChannel, items]
    );

    const [downloadModal, setDownloadModal] = useState<null | Boolean | React.ReactElement>(false);

    const closeDownloadModal = useCallback(() => setDownloadModal(null), [setDownloadModal]);

    const openDownloadModal = useCallback(
        () =>
            setDownloadModal(
                <DownloadModal dealerIds={dealerIds ?? []} onClose={closeDownloadModal} type="Reservations" />
            ),
        [setDownloadModal, dealerIds, closeDownloadModal]
    );

    const footer = (
        <FootContainer>
            <FootBar>
                <FootBarButton onClick={openDownloadModal}>download</FootBarButton>
            </FootBar>
        </FootContainer>
    );

    const title = t(titleKey);

    return (
        <>
            <List
                columns={columns}
                error={error as any}
                footer={footer as any}
                headerBottomComponent={<DealerSelectionDropDown dealerIds={dealerIds} onValueChanged={setDealerIds} />}
                headerLeftComponent={<ListSearch initialValue={search} onSubmit={setSearch} />}
                items={itemsData}
                loading={isLoading}
                onItemClick={item =>
                    history.push(`/workflow/reservations/${item.version.id}`, { showCustomerSection: true })
                }
                onSort={onSort}
                paging={paging}
                sortedOn={[sortedOn.type, sortedOn.order]}
                title={title}
            />
            {downloadModal}
        </>
    );
};

export default ReservationList;
