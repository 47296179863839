import { cloneDeep } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MaskDirection, Provider } from '../../../../schema';
import { getCurrentCountry, getCompany, getRuntimeSettings } from '../../../../selectors';
import Page from './Page';

const channelSetting = {
    new: {
        isActive: false,
        isDealerOptionsEnabled: false,
        isCoeEnabled: false,
        isCoeEditable: false,
        isPpsrAndEstablishmentEnabled: false,
        allowReverseCalculator: false,
        allowOptions: false,
        isPromoCodeEnabled: false,
        allowSharing: false,
        allowPrivateAccess: true,
        allowPublicAccess: false,
        isDepositPaymentMandatory: false,
        allowFinanceApplication: false,
        isFinanceApplicationMandatory: false,
        filterByMonthlyInstalment: false,
        allowProceedWithCustomerDevice: false,
        isChatbotEnabled: false,
        alwaysShowPromoCode: false,
        isInsuranceEnabled: false,
        isLuxuryTaxEnabled: false,
    },
    used: {
        isActive: false,
        allowReverseCalculator: false,
        allowTradeIn: false,
        allowTestDrive: false,
        isPromoCodeEnabled: false,
        allowSharing: false,
        allowPrivateAccess: true,
        allowPublicAccess: false,
        isDepositPaymentMandatory: false,
        allowFinanceApplication: false,
        isFinanceApplicationMandatory: false,
        filterByMonthlyInstalment: false,
        allowProceedWithCustomerDevice: false,
        isChatbotEnabled: false,
        alwaysShowPromoCode: false,
    },
    express: {
        isActive: false,
        isDealerOptionsEnabled: false,
        isCoeEnabled: false,
        isCoeEditable: false,
        isPpsrAndEstablishmentEnabled: false,
        allowReverseCalculator: false,
        isPromoCodeEnabled: false,
        isDepositPaymentMandatory: false,
        allowFinanceApplication: false,
        isFinanceApplicationMandatory: false,
        allowProceedWithCustomerDevice: false,
        alwaysShowPromoCode: false,
        isInsuranceEnabled: false,
        isLuxuryTaxEnabled: false,
    },
    event: {
        isActive: false,
        hasMyInfoIntegration: false,
        isDepositPaymentMandatory: false,
        alwaysShowPromoCode: false,
        isChatbotEnabled: false,
        isPromoCodeEnabled: false,
    },
};

const Creation = () => {
    const { currency, rounding } = useSelector(getCurrentCountry);
    const company = useSelector(getCompany);
    const settings = useSelector(getRuntimeSettings);

    const initialValues = useMemo(
        () => ({
            companyId: company.id,
            currency,
            company: { name: company.name, description: company.description },
            languages: ['en'],
            rounding: cloneDeep(rounding),
            matchExistingCustomer: true,
            email: {
                provider: Provider.SYSTEM,
                fromEmail: settings?.systemEmail,
                isSslEnabled: true,
                isAuthenticationRequired: true,
            },
            sendSalesPersonConfirmationEmail: false,
            useExternalCalculator: false,
            usePorscheFinder: false,
            useIccCheckout: false,
            useAutoplay: false,
            mask: { direction: MaskDirection.NONE, count: 0 },
            channelSetting,
        }),
        [company, currency, rounding, settings]
    );

    return <Page initialValues={initialValues} />;
};

export default Creation;
