import { useApolloClient } from '@apollo/client';
import cs from 'classnames';
import React, { useState, useCallback, useMemo } from 'react';
import { Field } from 'redux-form';
import { UploadPurpose } from '../../../../../../../schema';
import {
    DocumentContainer,
    Title,
    TabContainer,
    Tabs,
    TabItem,
    DocumentBg,
    DocumentGrid,
} from '../../../../../../ui/Document';
import { deleteDocument } from '../../Page.graphql';
import { useFormContext } from '../context';
import DocumentPreview from './DocumentPreview';
import DocumentUpload from './DocumentUpload';

const categories = [
    { label: 'Agreement', id: 1, source: ['AGREEMENT'] },
    {
        label: 'Identity',
        id: 3,
        source: [
            UploadPurpose.NRIC_FRONT,
            UploadPurpose.NRIC_BACK,
            UploadPurpose.CUSTOMER_IDENTITY,
            UploadPurpose.NRIC_NAMED_CARD,
            UploadPurpose.GUARANTOR_IDENTITY,
        ],
        purpose: UploadPurpose.CUSTOMER_IDENTITY,
    },
    {
        label: 'Attachment',
        id: 2,
        source: [
            UploadPurpose.CUSTOMER_ATTACHED,
            UploadPurpose.VEHICLE_LOG_CARD,
            UploadPurpose.GUARANTOR_ATTACHED,
            UploadPurpose.VSO_UPLOAD,
        ],
        purpose: UploadPurpose.CUSTOMER_ATTACHED,
    },
];

const Document = () => {
    const { disabled, values, change } = useFormContext();
    const { id: applicationId, attachments } = values;

    const [activeCategory, setActiveCategory] = useState(categories[0]);

    const client = useApolloClient();

    const selectCategory = useCallback(category => setActiveCategory(category), [setActiveCategory]);

    const renderCategoryMenu = useCallback(
        category => {
            const { id, source, label } = category;
            const counter = attachments.filter(item => source.find(i => i === item.purpose)).length;

            return (
                <TabItem
                    key={id}
                    className={cs('tab-item', { active: category === activeCategory })}
                    onClick={() => selectCategory(category)}
                >
                    {label}
                    {counter > 0 && <span>({counter})</span>}
                </TabItem>
            );
        },
        [activeCategory, attachments, selectCategory]
    );

    const removeMutation = useCallback(
        ({ referenceId, attachmentId }) =>
            client.mutate({
                mutation: deleteDocument,
                variables: {
                    applicationId: referenceId,
                    attachmentId,
                },
            }),
        [client]
    );

    const activeDocuments = useMemo(
        () => attachments.filter(item => activeCategory.source.find(i => i === item.purpose)),
        [activeCategory.source, attachments]
    );

    const renderDocuments = useCallback(
        document =>
            document && (
                <DocumentPreview
                    key={document.id}
                    change={change}
                    document={document}
                    files={attachments}
                    name="attachments"
                    referenceId={applicationId}
                    removeMutation={removeMutation}
                    showRemove={activeCategory?.id !== 1}
                />
            ),
        [activeCategory, applicationId, attachments, change, removeMutation]
    );

    return (
        <DocumentContainer>
            <Title>Document Management</Title>
            <TabContainer>
                <Tabs>{categories.map(renderCategoryMenu)}</Tabs>
                <DocumentBg>
                    <DocumentGrid>
                        {activeDocuments.map(renderDocuments)}
                        {activeCategory.id !== 1 && (
                            <Field
                                activeCategory={activeCategory}
                                applicationId={applicationId}
                                component={DocumentUpload}
                                disabled={disabled}
                                name="attachments"
                            />
                        )}
                    </DocumentGrid>
                </DocumentBg>
            </TabContainer>
        </DocumentContainer>
    );
};

export default Document;
