import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CalculatorVisibility, CoeVehicleCondition, EventExternalSite } from '../../../../schema';
import { getCurrentCountry, getCurrentZone } from '../../../../selectors';
import Page from './Page';

const Creation = () => {
    const zone = useSelector(getCurrentZone);
    const country = useSelector(getCurrentCountry);

    const initialValues = useMemo(
        () => ({
            isActive: true,
            dealerIds: [],
            period: {
                start: new Date(),
                startTimeZone: zone?.timezone,
                endTimeZone: zone?.timezone,
            },
            setting: {
                isCoeEnabled: false,
                isCoeEditable: false,
                allowTradeIn: false,
                allowTradeInAmountInput: false,
                allowTestDrive: false,
                isDepositPaymentMandatory: false,
                isChatbotEnabled: false,
                isPromoCodeEnabled: false,
                isPpsrAndEstablishmentEnabled: false,
                isReservationInstructionEnabled: false,
                reservationInstruction: null,
                reservationPeriod: 0,
                access: true,
                enablePriceDisclaimer: false,
                priceDisclaimers: [],
                externalSite: EventExternalSite.NONE,
                calculatorVisibility: CalculatorVisibility.ON,
                applyForFinancing: false,
                bookingPayment: {
                    amount: country?.channelSetting?.event?.bookingPayment?.amount || 0,
                },
                coeVehicleCondition: CoeVehicleCondition.ALL,
                isAppointmentEnabled: false,
            },
        }),
        [zone, country]
    );

    return <Page initialValues={initialValues} />;
};

export default Creation;
