import React from 'react';
import { useSelector } from 'react-redux';
import { getGlobalPermissions } from '../../../../../../selectors';
import CustomerOptionsProvider from '../../../../Customer/useOptions';
import Customer from './Customer';
import InsuranceDetails from './InsuranceDetails';
import Summary from './Summary';
import Vehicle from './Vehicle';
import { useFormContext } from './context';

const Application = () => {
    const { disabled, values, allowPrimaryInfoChanges } = useFormContext();

    const { mayManageInsuranceApplications } = useSelector(getGlobalPermissions);

    return (
        <>
            <Summary dealerId={values.dealerId} disabled={!mayManageInsuranceApplications} id={values.id} />
            <CustomerOptionsProvider>
                <Customer
                    allowPrimaryInfoChanges={allowPrimaryInfoChanges}
                    application={values.application}
                    disabled={disabled}
                    withInsurance
                />
            </CustomerOptionsProvider>
            <Vehicle />
            <InsuranceDetails />
        </>
    );
};

export default Application;
