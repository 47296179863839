import { useApolloClient, useQuery } from '@apollo/client';
import { omit, set, get, isObject } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { submit } from 'redux-form';
import { addNotification } from '../../../../actions';
import { getCountryId, getCurrentUserCountryDealers, getGlobalPermissions, getUser } from '../../../../selectors';
import { EVENT_DELETE_NOTIFICATION, EVENT_SAVE_NOTIFICATION } from '../../../../shared/constants/notification';
import { handleResponseError, prepareForGraphQL } from '../../../../utilities/forms';
import useLanguageSelector from '../../../../utils/useLanguageSelector';
import useValidation from '../../../../utils/useValidation';
import { useModal } from '../../../Modal';
import FormLayout from '../../../ui/form/FormLayout';
import Form from './Form';
import validator from './Form/validate';
import { create, update, remove, getData, uploadEventIntroImage, deleteEventIntroImage } from './Page.graphql';

const Page = ({ initialValues = null }) => {
    const validate = useValidation(validator);
    const modal = useModal();
    const history = useHistory();
    const dispatch = useDispatch();

    const { mayManageEventChannel } = useSelector(getGlobalPermissions);

    const submitForm = useCallback(() => dispatch(submit('event')), [dispatch]);

    const client = useApolloClient();
    const countryId = useSelector(getCountryId);

    const languageSelector = useLanguageSelector();

    const onSubmit = useCallback(
        async ({ id, ...data }) => {
            const query = id ? update : create;
            const eventData = prepareForGraphQL(omit(['edm.introImage'], data));
            const eventSetting = eventData.setting;
            let variables = {
                event: {
                    ...eventData,
                    setting: {
                        ...eventData.setting,
                        reservationInstruction: eventSetting.isReservationInstructionEnabled
                            ? eventSetting.reservationInstruction
                            : null,
                        reservationPeriod: eventSetting.isReservationInstructionEnabled
                            ? eventSetting.reservationPeriod
                            : null,
                        priceDisclaimers: eventSetting.priceDisclaimers?.filter(
                            priceDisclaimer => !!priceDisclaimer?.trim()
                        ),
                        allowPrivateAccess: eventSetting.access,
                        allowPublicAccess: !eventSetting.access,
                    },
                },
                countryId,
                id,
            };

            const getStartDate = new Date(variables.event.period.start);
            getStartDate.setHours(0, 0, 0, 0);

            const getEndDate = new Date(variables.event.period.end);
            getEndDate.setHours(23, 59, 59, 999);

            variables = set('event.period.end', getEndDate, variables);
            variables = set('event.period.start', getStartDate, variables);

            variables.event = omit(
                [
                    'setting.allowFinder',
                    'setting.enablePriceDisclaimer',
                    'setting.access',
                    'setting.isReservationInstructionEnabled',
                ],
                variables.event
            );

            if (id) {
                // we need to remove some more dta
                variables.event = omit(
                    ['version', 'lastModified', 'consentsAndDeclarations', 'applications', 'identifier'],
                    variables.event
                );
            }

            let promise = client.mutate({ mutation: query, variables }).catch(handleResponseError);

            const { edm } = data;
            if (edm && edm.introImage instanceof File) {
                // must upload a new file
                promise = promise.then(({ data: { response } }) =>
                    client.mutate({
                        mutation: uploadEventIntroImage,
                        variables: { id: response.id, file: edm.introImage },
                    })
                );
            } else if (edm && edm.introImage === null && isObject(initialValues.edm?.introImage)) {
                // must remove an existing file
                promise = promise.then(({ data: { response } }) =>
                    client.mutate({ mutation: deleteEventIntroImage, variables: { id: response.id } })
                );
            }

            return promise.catch(handleResponseError);
        },
        [client, countryId, initialValues]
    );
    const onSubmitSuccess = useCallback(() => {
        dispatch(addNotification(EVENT_SAVE_NOTIFICATION));
        history.push('/event');
    }, [history, dispatch]);

    const onDelete = useCallback(() => {
        modal.confirm({
            title: '',
            content: 'Are you sure you want to delete this event?',
            options: [
                { label: 'No', action: () => null },
                {
                    label: 'Yes',
                    action: () =>
                        client.mutate({ mutation: remove, variables: { id: initialValues?.id } }).then(() => {
                            dispatch(addNotification(EVENT_DELETE_NOTIFICATION));
                            history.push('/event');
                        }),
                },
            ],
        });
    }, [client, dispatch, history, initialValues, modal]);

    const onCancel = useCallback(() => history.goBack(), [history]);

    const { data, loading } = useQuery(getData, { variables: { countryId }, fetchPolicy: 'cache-first' });

    const userDealers = useSelector(getCurrentUserCountryDealers);
    const user = useSelector(getUser) || {};
    const availableDealers = useMemo(() => {
        const dealers = get('availableDealers.items', data) ?? [];

        if (user.isSuperUser) {
            // return all dealers for super user
            return dealers;
        }

        const combinedDealers = [...userDealers, ...(initialValues?.dealerIds || [])];

        // only return dealers associated to user or initialValues
        return dealers
            .filter(({ value }) => combinedDealers.includes(value))
            .map(dealer => {
                // check if dealer is associated to user

                if (userDealers.includes(dealer.value)) {
                    return dealer;
                }

                // else it should be fixed
                return { ...dealer, isFixed: true };
            });
    }, [initialValues, data, user, userDealers]);
    const mayDelete = useMemo(
        () =>
            user?.isSuperUser ||
            (mayManageEventChannel && initialValues?.dealerIds?.every(dealerId => userDealers.includes(dealerId))),
        [initialValues, user, mayManageEventChannel, userDealers]
    );

    if (loading) {
        return null;
    }

    return (
        <FormLayout
            bodyComponent={
                initialValues && (
                    <Form
                        {...languageSelector}
                        availableDealers={availableDealers}
                        disabled={!mayManageEventChannel}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitSuccess={onSubmitSuccess}
                        validate={validate}
                    />
                )
            }
            moreActions={[
                mayManageEventChannel &&
                    initialValues?.id && {
                        label: 'delete',
                        onAction: onDelete,
                        disabled: !mayDelete,
                    },
                mayManageEventChannel && {
                    label: 'save',
                    onAction: submitForm,
                },
            ]}
            onCancel={onCancel}
            title="Events"
        />
    );
};

Page.propTypes = {
    initialValues: PropTypes.shape({}),
};

export default Page;
