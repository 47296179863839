import { get } from 'lodash/fp';
import { LicenseType } from '../../../../schema';
import * as validators from '../../../../utils/validators';

const prefix = (formSection?: string) => (path: string) => [formSection, path].filter(Boolean).join('.');

export const customerPrimaryInfoOnlyValidators = (formSection?: string) => {
    const prefixForm = prefix(formSection);

    return validators.compose(
        validators.only(
            validators.hasChanged(prefixForm('email.value')),
            validators.customerProperty(
                prefixForm('email'),
                validators.compose(
                    validators.requiredString(prefixForm('email.value')),
                    validators.validEmail(prefixForm('email.value'))
                )
            )
        ),
        validators.only(
            validators.hasChanged(prefixForm('emailBis')),
            validators.customerProperty(prefixForm('emailBis'), validators.validEmail(prefixForm('emailBis.value')))
        ),
        validators.only(
            validators.hasChanged(prefixForm('phoneBis.value')),
            validators.customerProperty(
                prefixForm('phoneBis'),
                validators.contextValidation(prefixForm('phoneBis.value'), 'phonePattern', 'Invalid Mobile Number')
            )
        )
    );
};

export const customerPrimaryInfoValidators = (formSection?: string) => {
    const prefixForm = prefix(formSection);

    return validators.compose(
        customerPrimaryInfoOnlyValidators(formSection),
        validators.customerProperty(
            prefixForm('details.maritalStatus'),
            validators.requiredString(prefixForm('details.maritalStatus.value'))
        ),
        validators.customerProperty(
            prefixForm('details.maritalStatus'),
            validators.requiredString(prefixForm('details.maritalStatus.value'))
        )
    );
};

export const customerValidators = (formSection?: string) => {
    const prefixForm = prefix(formSection);

    return validators.compose(
        customerPrimaryInfoValidators(formSection),
        validators.customerProperty(prefixForm('name'), validators.requiredString(prefixForm('name.value'))),
        validators.customerProperty(prefixForm('firstName'), validators.requiredString(prefixForm('firstName.value'))),
        validators.customerProperty(prefixForm('lastName'), validators.requiredString(prefixForm('lastName.value'))),
        validators.only(
            validators.hasChanged(prefixForm('phone.value')),
            validators.customerProperty(
                prefixForm('phone'),
                validators.compose(
                    validators.requiredString(prefixForm('phone.value')),
                    validators.contextValidation(prefixForm('phone.value'), 'phonePattern', 'Invalid Mobile Number')
                )
            )
        ),
        validators.only(
            validators.hasChanged(prefixForm('identityNumber.value')),
            validators.customerProperty(
                prefixForm('identityNumber'),
                validators.compose(
                    validators.requiredString(prefixForm('identityNumber.value')),
                    validators.validNIRC(
                        prefixForm('identityNumber.value'),
                        prefixForm('details.nationality.value'),
                        prefixForm('dateOfBirth.value')
                    )
                )
            )
        ),
        validators.only(
            validators.hasChanged(prefixForm('useCustomerBirthDay')),
            validators.customerProperty(
                prefixForm('dateOfBirth'),
                validators.requiredDate(prefixForm('dateOfBirth.value'))
            )
        ),
        validators.only(
            validators.hasChanged(prefixForm('details.gender')),
            validators.customerProperty(
                prefixForm('details.gender'),
                validators.requiredDate(prefixForm('details.genderdetails.gender'))
            )
        ),
        validators.only(
            validators.hasChanged(prefixForm('details.race')),
            validators.customerProperty(
                prefixForm('details.race'),
                validators.requiredDate(prefixForm('details.race.value'))
            )
        ),
        validators.only(
            validators.hasChanged(prefixForm('details.residentialStatus')),
            validators.customerProperty(
                prefixForm('details.residentialStatus'),
                validators.requiredDate(prefixForm('details.residentialStatus.value'))
            )
        ),
        validators.only(
            validators.hasChanged(prefixForm('details.residentialAddress')),
            validators.customerProperty(
                prefixForm('details.residentialAddress'),
                validators.compose(
                    validators.requiredString(prefixForm('details.residentialAddress.block')),
                    validators.maxStringLength(prefixForm('details.residentialAddress.block'), 10),
                    validators.requiredString(prefixForm('details.residentialAddress.building')),
                    validators.maxStringLength(prefixForm('details.residentialAddress.building'), 66),
                    validators.requiredString(prefixForm('details.residentialAddress.country')),
                    validators.requiredString(prefixForm('details.residentialAddress.postalCode')),
                    validators.maxStringLength(prefixForm('details.residentialAddress.postalCode'), 6),
                    validators.requiredString(prefixForm('details.residentialAddress.street')),
                    validators.maxStringLength(prefixForm('details.residentialAddress.street'), 66),
                    validators.requiredString(prefixForm('details.residentialAddress.unit')),
                    validators.maxStringLength(prefixForm('details.residentialAddress.unit'), 10)
                )
            )
        ),
        validators.only(
            validators.hasChanged(prefixForm('details.employment.assessableIncome')),
            validators.customerProperty(
                prefixForm('details.employment.assessableIncome'),
                validators.requiredNumber(prefixForm('details.employment.assessableIncome.value'))
            )
        ),
        validators.only(
            validators.hasChanged(prefixForm('details.employment.assessmentYear')),
            validators.compose(
                validators.requiredNumber(prefixForm('details.employment.assessmentYear.value')),
                validators.minStringLength(
                    prefixForm('details.employment.assessmentYear.value'),
                    4,
                    'Must be exactly 4 digits'
                ),
                validators.maxStringLength(
                    prefixForm('details.employment.assessmentYear.value'),
                    4,
                    'Must be exactly 4 digits'
                )
            )
        ),
        validators.only(
            validators.hasChanged(prefixForm('details.employment.employer')),
            validators.compose(
                validators.requiredString(prefixForm('details.employment.employer.value')),
                validators.maxStringLength(prefixForm('details.employment.employer.value'), 124)
            )
        ),
        validators.only(
            validators.hasChanged(prefixForm('details.employment.occupation')),
            validators.customerProperty(
                prefixForm('details.employment.occupation'),
                validators.compose(
                    validators.requiredString(prefixForm('details.employment.occupation.value')),
                    validators.maxStringLength(prefixForm('details.employment.occupation.value'), 100)
                )
            )
        ),
        validators.forEach(prefixForm('details.drivingLicense'), index =>
            validators.customerProperty(
                prefixForm(`details.drivingLicense[${index}]`),
                validators.compose(
                    validators.only(
                        values =>
                            get(prefixForm(`details.drivingLicense[${index}].type`), values) ===
                                LicenseType.PROVISIONAL ||
                            get(prefixForm(`details.drivingLicense[${index}].type`), values) === LicenseType.QUALIFIED,
                        validators.compose(
                            validators.requiredDate(prefixForm(`details.drivingLicense[${index}].expiryDate`)),
                            validators.requiredString(prefixForm(`details.drivingLicense[${index}].validity`))
                        )
                    ),
                    validators.forEach(prefixForm(`details.drivingLicense[${index}].classes`), classIndex =>
                        validators.compose(
                            validators.only(
                                values =>
                                    get(prefixForm(`details.drivingLicense[${index}].type`), values) ===
                                    LicenseType.PROVISIONAL,
                                validators.requiredString(
                                    prefixForm(`details.drivingLicense[${index}].classes[${classIndex}].licenseClass`)
                                )
                            ),
                            validators.only(
                                values =>
                                    get(prefixForm(`details.drivingLicense[${index}].type`), values) ===
                                    LicenseType.QUALIFIED,
                                validators.compose(
                                    validators.requiredDate(
                                        prefixForm(`details.drivingLicense[${index}].classes[${classIndex}].issueDate`)
                                    ),
                                    validators.requiredString(
                                        prefixForm(
                                            `details.drivingLicense[${index}].classes[${classIndex}].licenseClass`
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
};

const validate = customerValidators();

export default validate;
