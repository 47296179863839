import { useQuery } from '@apollo/client';
import { isEmpty, trim } from 'lodash/fp';
import React, { useContext, createContext, ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../schema';
import { getCustomerOptions, GetCustomerOptionsQuery, GetCustomerOptionsQueryVariables } from './Edition.graphql';

export type Options = GetCustomerOptionsQuery['options'] & {
    title: Array<{ label: string; value: string }>;
};

const Context = createContext<null | undefined | Options>(null);

export const useCustomerOptions = () => useContext(Context);

export type CustomerOptionsProviderProps = {
    children: ReactElement | ReactNode;
};

export type Option<T> = {
    value: T;
    label: string;
};

const CustomerOptionsProvider = ({ children }: CustomerOptionsProviderProps) => {
    const { data } = useQuery<GetCustomerOptionsQuery, GetCustomerOptionsQueryVariables>(getCustomerOptions, {
        fetchPolicy: 'cache-first',
    });

    const { t } = useTranslation();

    const options = useMemo(() => {
        if (!data) {
            return undefined;
        }

        const title = [
            {
                label: t('common.options.title.mr'),
                value: Title.MR,
            },
            {
                label: t('common.options.title.ms'),
                value: Title.MS,
            },
        ];

        const claimDiscountOptions = [
            { value: 0, label: '0%' },
            { value: 10, label: '10%' },
            { value: 20, label: '20%' },
            { value: 30, label: '30%' },
            { value: 40, label: '40%' },
            { value: 50, label: '50%' },
        ];

        const yearsOfDrivingOptions = [
            { value: 4, label: '3-4' },
            { value: 8, label: '5-8' },
            { value: 10, label: '9-10' },
            { value: 11, label: '>10' },
        ];

        const translationMapper = <T,>({ label, ...values }: Option<T>) => ({ ...values, label: t(label) });

        const genders = data.options.genders.map(translationMapper);

        const maritalStatuses = data.options.maritalStatuses.map(translationMapper);

        const downpaymentTo = data.options.downpaymentTo.map(translationMapper);

        const residentialStatuses = data.options.residentialStatuses.map(translationMapper);

        const cities = data.options.cities.map(({ name, countryCode }) => {
            const translatedName = t(`common.options.city.${countryCode}.${trim(name)}`);

            return {
                name: !isEmpty(translatedName) ? translatedName : name,
                countryCode,
            };
        });

        return {
            ...data.options,
            title,
            genders,
            maritalStatuses,
            residentialStatuses,
            cities,
            claimDiscountOptions,
            downpaymentTo,
            yearsOfDrivingOptions,
        };
    }, [data, t]);

    return <Context.Provider value={options}>{children}</Context.Provider>;
};

export default CustomerOptionsProvider;
